import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import H1 from "../components/h1"
import Iframe from "../components/iframe"
import Layout from "../components/layout"
import MapLink from "../components/mapLink"
import Narrow from "../components/narrow"
import QuoteBox from "../components/quoteBox"
import Img from "gatsby-image"

const StyledIndexPage = styled.div`
  .centered {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  hr {
    opacity: 0.3;
    margin-bottom: 3rem;
  }
  .image-container {
    /* margin-top: 1rem; */
    &.narrow {
      padding: 0 20%;
    }
  }
  .news-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    .image-container {
      &.narrow {
        padding: 0;
      }
    }
  }
`

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query indexQuery {
      indexBg: file(relativePath: { eq: "wash-top.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      nayax: file(relativePath: { eq: "Nayax.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      indexImg: file(relativePath: { eq: "man-1.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news1: file(relativePath: { eq: "news/IMG_0155.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news2: file(relativePath: { eq: "news/IMG_2410.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news3: file(relativePath: { eq: "news/IMG_0376.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news4: file(relativePath: { eq: "news/IMG_0412.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news5: file(relativePath: { eq: "news/IMG_3923.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news6: file(relativePath: { eq: "news/hellden.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      news7: file(relativePath: { eq: "news/IMG_2586.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      rissne: file(relativePath: { eq: "news/rissne.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sundbyberg: file(relativePath: { eq: "news/sundbyberg.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      castellum: file(relativePath: { eq: "news/castellum.jpeg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      uppsala: file(relativePath: { eq: "news/uppsala_garage.jpeg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      odenplan: file(relativePath: { eq: "news/odenplan.jpeg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      uppsala2: file(relativePath: { eq: "news/uppsalatvatt.jpeg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const news = [
    {
      image: query.uppsala2.childImageSharp.fluid,
      title: "Uppsala",
      text:
        "Vi var med under invigningen av Uppsala kommuns cykelgarage. Nu finns det två tvättar där som tar hand om din cykel.",
    },
    {
      image: query.odenplan.childImageSharp.fluid,
      title: "Odenplan",
      text: "Reklaminspelning på Odenplan med sol och MTR",
    },
    // {
    //   image: query.uppsala.childImageSharp.fluid,
    //   title: "Uppsala",
    //   text: "Cykeltvättar inom en snar framtid i Uppsala kommuns cykelgarage.",
    // },
    {
      image: query.castellum.childImageSharp.fluid,
      title: "Castellum",
      text: "Event med Castellum på Lilla bantorget, torsgatan.",
    },
    {
      image: query.sundbyberg.childImageSharp.fluid,
      title: "Sundbyberg",
      text: "Idag kör vi på Sundbybergs Torg, tvätt och service.",
    },
    {
      image: query.rissne.childImageSharp.fluid,
      title: "Rissne",
      text:
        "Jippo på Rissne torg, vi bjuder på en cykeltvätt och en liten service! Välkomna 🚴🏼‍♀️",
    },
    {
      image: query.news1.childImageSharp.fluid,
      title: "Tvätthus",
      text:
        "Specialritad av byggbolag i Gävle för kunder, som vill att den smälter in i gammal stadsmiljö.",
    },
    {
      image: query.news2.childImageSharp.fluid,
      title: "Ny tvätt",
      text:
        "Tvätt nr 2 i Sverige anländer står nu på nords sport o motor Bromma.",
    },
    {
      image: query.news3.childImageSharp.fluid,
      title: "Cykeltvätt i Åre",
      text: "Åretorg i samarbete med Skistar.",
    },
    // {
    //   image: query.news4.childImageSharp.fluid,
    //   title: "Självförsörjande tvätthus",
    //   text: "Komplett självförsörjande hus,solceller, verkstadsmöjlighet mm.",
    // },
    // {
    //   image: query.news5.childImageSharp.fluid,
    //   title: "Cykeltvätten på lilla bantorget",
    //   text: "Lilla Bantorget, torsgatan, Stockholm.",
    // },
    // {
    //   image: query.news6.childImageSharp.fluid,
    //   title: "Daniel Helldén",
    //   text: "Trafikborgarrådet i Stockholm testar en cykeltvätt.",
    // },
    {
      image: query.news7.childImageSharp.fluid,
      title: "Cykelvasan",
    },
  ]
  console.log(query)

  return (
    <Layout
      headerImage={query.indexBg.childImageSharp.fluid}
      headerText={"Älska din tvåhjuling"}
    >
      <StyledIndexPage>
        <Narrow>
          <H1>En revolution inom cykelvård</H1>
          <p className="centered">
            Framför allt så skapar cykeltvätten nöjda och glada cyklister vilket
            i sin tur leder till en rad goda dominoeffekter både för klimat och
            personlig hälsa. Genom att tillsammans skapa möjligheter och
            förutsättningar för ökad cykling så bidrar vi gemensamt till ett
            minskat koldioxidutsläpp och ökad folkhälsa.
          </p>
          <QuoteBox
            fluid={query.indexImg.childImageSharp.fluid}
            content={
              <p>
                "Många cyklister ser fördelarna ur en miljöaspekt att använda
                cykel till och från arbetet och i det vardagliga livet och att
                tvätta cykeln i en cykeltvätt går helt i linje med detta."
              </p>
            }
          />
          <hr />
          <H1>Nyheter</H1>
          <div className="news-container">
            {news.map(item => (
              <QuoteBox
                column
                key={item.title}
                fluid={item.image}
                content={
                  <>
                    <h3>{item.title}</h3>
                    {item.text ? <p>{item.text}</p> : null}
                  </>
                }
              />
            ))}
          </div>
          <hr />
          <H1>Vår historia</H1>
          <p className="centered">
            Tanken till cykeltvätten väcktes i samband med att Beckomberga
            Sjukhus-område byggdes med närmare 3000 nya lägenheter i direkt
            anknytning till Sten Nords cykelverkstad. De inflyttade i området
            stod i regel utan bil och använde cykel som främsta transportmedel
            och antalet tvättade cyklar ökade markant för cykelverkstaden som då
            tvättade cyklar för hand. Detta var en naturlig utveckling då
            antalet cyklister ökat i hela landet, i synnerhet i Stockholm, och i
            stora lägenhetsområden hamnade bilen ofta i skymundan av cykeln för
            vardaglig transport. Men en smutsig cykel i hallen eller på
            balkongen är inte lika lockande för gemene man och endast ett fåtal
            har tillgång till en plats att tvätta cykeln på.
          </p>
          <Iframe>
            <iframe
              width="1280"
              title="Cykeltvätten"
              height="720"
              src="https://www.youtube.com/embed/oqW3rv50UIQ"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Iframe>
          <p className="centered">
            Sten Nord och Johan Bjerker startade kort därpå aktiebolaget Bicycle
            Wash AB som erbjuder cyklister en portabel cykeltvätt. En unik
            maskin som fungerar enligt samma princip som en vanlig biltvätt, men
            i mindre format. Tillgängligheten sätts i fokus och tvätten bör på
            sikt finnas på platser med stor cykeltillströmning. Kommer även
            finnas för i inbyggnadsvariant för vinterbruk.
          </p>
          <div className="centered">
            <Link to="/hitta">
              <MapLink />
            </Link>
          </div>
          <div style={{ height: 32 }} />
          <hr />
          <H1>Smidiga betalningar</H1>
          <p className="centered">
            I samarbete med Nayax har vi sett till att du kan betala enkelt med
            kort eller swish.
          </p>
          <div className="image-container narrow">
            <Img fluid={query.nayax.childImageSharp.fluid} alt="" />
          </div>
        </Narrow>
      </StyledIndexPage>
    </Layout>
  )
}

export default IndexPage
