import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { colors } from "../utils/siteVars"

const StyledQuoteBox = styled.div`
  display: flex;
  ${props => (props.column ? "flex-direction: column;" : "")}

  .image-container {
    min-width: 66%;
  }
  /* grid-template-columns: 2fr 1fr; */
  border: 1px solid ${colors.mediumGrey};
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 3rem;
  .text-container {
    padding: 1rem;
    background: ${colors.lightGreen};
    min-height: 100%;
    p {
      margin: 0;
    }
    h3 {
      margin: 0 0 0.7rem 0;
    }

    ul {
      margin-left: 1rem;
      list-style: initial;
      li {
        margin-bottom: 0.1rem;
      }
    }
  }
  button {
    padding: 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const QuoteBox = props => {
  return (
    <StyledQuoteBox column={props.column}>
      <div className="image-container">
        <Img
          fluid={props.fluid}
          alt=""
          imgStyle={{ objectFit: "cover", minHeight: "100%" }}
        />
      </div>
      <div className="text-container">{props.content}</div>
    </StyledQuoteBox>
  )
}

export default QuoteBox
